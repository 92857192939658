{
  "lang": "en",
  "title": "Find a slot",
  "description": "Find a shared time slot in your calendars with real-time feedback.",
  "settings": "Settings",
  "setup": "Setup",
  "days_range": "Days range",
  "time_range": "Time range",
  "best_results": "Best results",
  "no_results": "No results to display yet",
  "votes_one": "{{count}} vote",
  "votes_other": "{{count}} votes",
  "today": "today",
  "hours_one": "{{count}}h",
  "hours_other": "{{count}}hr",
  "event_name": "Event name",
  "your_name": "Your name",
  "instructions": "Instructions",
  "how_to_select": "<b>Click</b> or <b>Drag</b> to select/deselect.",
  "how_to_invite": "Share the link.",
  "participants": "Participants",
  "include_weekends": "Include weekends",
  "half_hours_intervals": "Half hour intervals",
  "duration": "Duration",
  "feedback_github": "For bugs and feature requests please create a ticket <a target=\"_blank\" href=\"https://github.com/libasoles/croquet-findaslot-app/issues\">here</a>.",
  "feedback_mail": "For any other kind of comment drop me a line at gperez<span>@</span>10pines<span>.</span>com",
  "feedback_thanks": "Thanks for taking the time for this.",
  "your_available_slots": "Your available slots",
  "nothing_selected_yet": "Nothing selected yet",
  "other_user_nothing_selected_yet": "Nothing selected yet",
  "select_a_participant": "Select a participant",
  "check_mark_hint": "They have a check mark if they already selected time slots",
  "other_user_selection": "{{otherUser}} selection",
  "comparing": "Comparing",
  "no_overlap": "No matches to display",
  "you_have_common_slots": "Hurray! You have common slots",
  "new_event": "New event",
  "no_other_events_yet": "You don't have other events to display yet",
  "schedule": "Schedule:",
  "schedule_call_to_action": "Go ahead and schedule:",
  "history": "History",
  "two_fingers_to_scroll": "One finger to select, two fingers to scroll",
  "first_time_here": "<b>First time here?</b> <span class='logo'></span> <b>Find a Slot</b> allows you to explore shared time slots in your calendars with real-time feedback.",
  "setup_done": "Great. Now share the link. And select your own availability.",
  "setup_almost_done": "Almost there. Fill up the event name.",
  "setup_select_availability": "Good. Go ahead and select your availability.",
  "please_set_your_name": "Remember to fill in your name, so other users can identify you."
}
