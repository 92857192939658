{
  "lang": "es",
  "title": "Sale o sale",
  "description": "Combina reuniones y ensayos con feedback en tiempo real.",
  "settings": "Configuración",
  "setup": "Setup",
  "days_range": "Rango de días",
  "time_range": "Rango de horas",
  "best_results": "Mejores horarios",
  "no_results": "Aún nadie marcó sus horarios",
  "votes_one": "{{count}} voto",
  "votes_other": "{{count}} votos",
  "today": "hoy",
  "hours_one": "{{count}}hs",
  "hours_other": "{{count}}hs",
  "event_name": "Nombre del evento",
  "your_name": "Tu nombre",
  "instructions": "Instrucciones",
  "how_to_select": "<b>Click</b> o <b>arrastra</b> para seleccionar.",
  "how_to_invite": "Comparte el link.",
  "participants": "Participantes",
  "include_weekends": "Incluir fines de semana",
  "half_hours_intervals": "Intervalos de media hora",
  "duration": "Duración",
  "feedback_github": "Por errores o pedidos de funcionalidad por favor crea un ticket <a target=\"_blank\" href=\"https://github.com/libasoles/croquet-findaslot-app/issues\">aca</a>.",
  "feedback_mail": "Cualquier otro tipo de comentarios a gperez<span>@</span>10pines<span>.</span>com",
  "feedback_thanks": "Gracias.",
  "your_available_slots": "Tu disponibilidad",
  "nothing_selected_yet": "Aun no marcaste tu disponibilidad",
  "other_user_nothing_selected_yet": "Aun no marcó su disponibilidad",
  "select_a_participant": "Selecciona algún participante",
  "check_mark_hint": "Tendrán un tilde si ya marcaron su disponibilidad",
  "other_user_selection": "La selección de {{otherUser}}",
  "comparing": "Comparando",
  "no_overlap": "No hay coincidencias para mostrar",
  "you_have_common_slots": "Bien! Tienen horarios en común",
  "new_event": "Nuevo evento",
  "no_other_events_yet": "Aún no hay otros eventos para mostrar",
  "schedule": "Agendar:",
  "schedule_call_to_action": "Click para agendar:",
  "history": "Historial",
  "two_fingers_to_scroll": "Usa un dedo para seleccionar, y dos para deslizar la pantalla",
  "first_time_here": "<b>Primera vez?</b> <span class='logo'></span> <b>Sale o sale</b> te permite explorar horarios en común, con feedback en tiempo real.",
  "setup_done": "Perfecto! Ahora comparte el link. Y selecciona tu propia disponibilidad.",
  "setup_almost_done": "Casi listo. Falta completar el nombre del evento.",
  "setup_select_availability": "Bien. Selecciona tu propia disponibilidad horaria.",
  "please_set_your_name": "Llena tu nombre! Así los demás pueden identificarte."
}
